import { useParams } from "react-router-dom"

function BusinessConnection() {

    let { businessID } = useParams();

    // if signed in redirect to dashboard else: if businessID is empty redirect to landing page

    return(
        <div>
            <p>{businessID}</p>
        </div>
    );
}

export default BusinessConnection;