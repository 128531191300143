import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom';

import { app } from "./firebaseConfiguration";
import * as auth from "firebase/auth";
import * as firestore from "firebase/firestore";
import * as storage from "firebase/storage";

import LandingPage from "./LandingPage";
import BusinessConnection from './BusinessConnection';

function App() {
    let navigate = useNavigate();

    useEffect(() => {

    }, []);

    return (
        <Routes>
            <Route path='/' element={<LandingPage/>}/>      
            <Route path='/connect/:businessID' element={<BusinessConnection/>}/>                    
        </Routes>
    );
}

export default App;
