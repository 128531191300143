import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyAa6vxIFILyJ0fPv5HladROqDdXxrcPFQo",
    authDomain: "rewardlystdotcom.firebaseapp.com",
    projectId: "rewardlystdotcom",
    storageBucket: "rewardlystdotcom.appspot.com",
    messagingSenderId: "723784070046",
    appId: "1:723784070046:web:adf4460e6be9c4a86f9c8f",
    measurementId: "G-2ZHGC3RYXR"
};

export const app = initializeApp(firebaseConfig);