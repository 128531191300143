function LandingPage(){
    return(
        // <div style={{display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center", justifyContent: "center", justifyItems: "center", height: "100vh", width: "100%", backgroundColor: "#f43f5e", paddingRight: 15, paddingLeft: 15}}>
        <div style={{display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center", justifyContent: "center", justifyItems: "center", position: "absolute", top: 0, left: 0, width: "100%", height: "100%", backgroundColor: "#f43f5e"}}>
            <svg xmlns="http://www.w3.org/2000/svg" style={{height: 100, width: 100}} fill="none" viewBox="0 0 24 24" stroke="#FFF" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v13m0-13V6a2 2 0 112 2h-2zm0 0V5.5A2.5 2.5 0 109.5 8H12zm-7 4h14M5 12a2 2 0 110-4h14a2 2 0 110 4M5 12v7a2 2 0 002 2h10a2 2 0 002-2v-7" />
            </svg>
            <h1 style={{padding: 0, margin: 0, color: "#FFF", fontWeight: "bolder", textAlign: "center"}}>RewardLyst</h1>
            <p style={{padding: 0, marginTop: 15, color: "#FFF", paddingRight: 15, paddingLeft: 15, textAlign: "center"}}>Universal Rewards Platform for Small Businesses</p>
        </div>        
    );
}

export default LandingPage;